import classNames from 'classnames';
export default function resume(){
     const skills = [
        {
         name: 'Frontend development',
         skills: [
           {
             Ename: 'JavaScript',
             level: 6,
           },
           {
             Ename: 'React',
             level: 6,
           },
           {
             Ename: 'CSS',
             level: 8,
           },
         ],
       },
       {
         name: 'System development',
         skills: [
           {
             Ename: 'C',
             level: 8,
           },
           {
             Ename: 'C#',
             level: 8,
           },
           {
             Ename: 'Rust',
             level: 4,
           },
         ],
       },
     ];
    return (
        <div className='bg-neutral-100'>
        <div className={classNames("px-4 py-16 md:py-24 lg:px-8",'mx-auto max-w-screen-lg')}>
        <div className="flex flex-col divide-y-2 divide-neutral-300">
        <div className="grid grid-cols-1 gap-y-4 py-8 first:pt-0 last:pb-0  md:grid-cols-4">
      <div className="col-span-1 flex justify-center md:justify-start">
        <div className="relative h-max">
          <h2 className="text-xl font-bold uppercase text-neutral-800">Education</h2>
          <span className="absolute inset-x-0 -bottom-1 border-b-2 border-orange-400" />
        </div>
      </div>

      <div className="col-span-1 flex flex-col md:col-span-3">
      <div className="flex flex-col pb-8 text-center last:pb-0 md:text-left">
      <div className="flex flex-col pb-4">
        <h2 className="text-xl font-bold">Bachelor of Information Technology (Computer Science)</h2>
        <div className="flex items-center justify-center gap-x-2 md:justify-start">
          <span className="flex-1 text-sm font-medium italic sm:flex-none">Queensland University of Technology </span>
          <span>•</span>
          <span className="flex-1 text-sm sm:flex-none">August 2023</span>
        </div>
        
      </div>
      <p>Driven by my initial exposure to the IT industry, I embarked on a transformative journey to pursue a Bachelor's degree in Computer Science. Throughout this educational pursuit, I acquired a comprehensive understanding of various project management techniques and programming design paradigms. Additionally, I gained proficiency in a diverse range of programming languages and cutting-edge technologies.</p>
    </div>  
    <div className="flex flex-col pb-8 text-center last:pb-0 md:text-left">
      <div className="flex flex-col pb-4">
    <h2 className="text-xl font-bold">Diploma of Information Technology Networking</h2>
    <div className="flex items-center justify-center gap-x-2 md:justify-start">
          <span className="flex-1 text-sm font-medium italic sm:flex-none">Tafe Queensland </span>
          <span>•</span>
          <span className="flex-1 text-sm sm:flex-none">December 2019</span>
        </div>
        <p>I embarked on my educational journey by enrolling at Tafe, where I kick-started my path to knowledge. After completing high school, I made the decision to pursue a diploma in Information Technology Networking. This program provided me with a comprehensive understanding of various skills and techniques involved in designing and establishing networking infrastructures within enterprise environments. Through this course, I not only gained valuable expertise but also unearthed my genuine passion for IT, which fueled my determination to further my education in this field. </p>
    </div>
    </div>
      </div>
      
    </div>
    <div className="grid grid-cols-1 gap-y-4 py-8 first:pt-0 last:pb-0  md:grid-cols-4">
    <div className="col-span-1 flex justify-center md:justify-start">
        <div className="relative h-max">
          <h2 className="text-xl font-bold uppercase text-neutral-800">Work</h2>
          <span className="absolute inset-x-0 -bottom-1 border-b-2 border-orange-400" />
        </div>
      </div>
      <div className="col-span-1 flex flex-col md:col-span-3">
      <div className="flex flex-col pb-8 text-center last:pb-0 md:text-left">
      <div className="flex flex-col pb-4">
        <h2 className="text-xl font-bold">Kitchen Hand</h2>
        <div className="flex items-center justify-center gap-x-2 md:justify-start">
          <span className="flex-1 text-sm font-medium italic sm:flex-none">Red Rooster</span>
          <span>•</span>
          <span className="flex-1 text-sm sm:flex-none">June 2019 - Present</span>
        </div>
        
      </div>
     
    </div>  

   
      </div>
      
        </div>
        <div className="grid grid-cols-1 gap-y-4 py-8 first:pt-0 last:pb-0  md:grid-cols-4">
      <div className="col-span-1 flex justify-center md:justify-start">
        <div className="relative h-max">
          <h2 className="text-xl font-bold uppercase text-neutral-800">Skills</h2>
          <span className="absolute inset-x-0 -bottom-1 border-b-2 border-orange-400" />
        </div>
      </div>
      {/*here*/}
      <div className={classNames("col-span-1 flex flex-col md:col-span-3","grid grid-cols-1 gap-6 md:grid-cols-2")}>
      <div className="flex flex-col">
      <span className="text-center text-lg font-bold">{skills[0].name}</span>
      <div className="flex flex-col gap-y-2">
        <div className="flex flex-col">
        <span className="ml-2 text-sm font-medium">{skills[0].skills[0].Ename}</span>
        <div className="h-5 w-full overflow-hidden rounded-full bg-neutral-300">
            <div className="h-full rounded-full bg-orange-400" style={{width: `${Math.round((skills[0].skills[0].level/ 10) * 100)}%`}} />
        </div>
    </div>
    <div className="flex flex-col">
        <span className="ml-2 text-sm font-medium">{skills[0].skills[1].Ename}</span>
        <div className="h-5 w-full overflow-hidden rounded-full bg-neutral-300">
            <div className="h-full rounded-full bg-orange-400" style={{width: `${Math.round((skills[0].skills[1].level/ 10) * 100)}%`}} />
        </div>
    </div>
    <div className="flex flex-col">
        <span className="ml-2 text-sm font-medium">{skills[0].skills[2].Ename}</span>
        <div className="h-5 w-full overflow-hidden rounded-full bg-neutral-300">
            <div className="h-full rounded-full bg-orange-400" style={{width: `${Math.round((skills[0].skills[2].level/ 10) * 100)}%`}} />
        </div>
    </div>
      </div>
      </div>
      <div className="flex flex-col">
      <span className="text-center text-lg font-bold">{skills[1].name}</span>
      <div className="flex flex-col gap-y-2">
      <div className="flex flex-col">
        <span className="ml-2 text-sm font-medium">{skills[1].skills[0].Ename}</span>
        <div className="h-5 w-full overflow-hidden rounded-full bg-neutral-300">
            <div className="h-full rounded-full bg-orange-400" style={{width: `${Math.round((skills[1].skills[0].level/ 10) * 100)}%`}} />
        </div>
    </div>
    <div className="flex flex-col">
        <span className="ml-2 text-sm font-medium">{skills[1].skills[1].Ename}</span>
        <div className="h-5 w-full overflow-hidden rounded-full bg-neutral-300">
            <div className="h-full rounded-full bg-orange-400" style={{width: `${Math.round((skills[1].skills[1].level/ 10) * 100)}%`}} />
        </div>
    </div>
    <div className="flex flex-col">
        <span className="ml-2 text-sm font-medium">{skills[1].skills[2].Ename}</span>
        <div className="h-5 w-full overflow-hidden rounded-full bg-neutral-300">
            <div className="h-full rounded-full bg-orange-400" style={{width: `${Math.round((skills[1].skills[2].level/ 10) * 100)}%`}} />
        </div>
    </div> 
      </div>
      </div>
      </div>
      </div>
        </div>
        </div>
        </div>
    )
}