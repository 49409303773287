import {ChevronDownIcon} from '@heroicons/react/24/outline';
import {Link} from 'react-scroll'; 
import Socals from './socials';
import classNames from 'classnames';
let actions = [
   {
    href: `contact`,
    text: 'Contact',
    primary: false,
  },
]
export default function Hero(){
return (
<div class="hero-image" id='hero'>
    <div className="relative flex h-screen w-full items-center justify-center">
    <div className="z-10  max-w-screen-lg px-4 lg:px-0">
          <div className="flex flex-col items-center gap-y-6 rounded-xl bg-gray-800/40 p-6 text-center shadow-lg backdrop-blur-sm">
          <h1 className="text-4xl font-bold text-white sm:text-5xl lg:text-7xl" >I'm Daniel Heinrich</h1>
          <p className="prose-sm text-stone-200 sm:prose-base lg:prose-lg">
      I'm a Software Engineer based in Brisbane; I specialise in both full stack website development and high-performance system programming.
      </p>
      <p className="prose-sm text-stone-200 sm:prose-base lg:prose-lg">
      In my spare time, I enjoy working on personal projects, playing video games, and playing tabletop roleplaying games with friends.
      </p>
      <div className="flex gap-x-4 text-neutral-100">
      <Socals />
      </div>
      <div className="flex w-full justify-center gap-x-4">
      {actions.map(({href, text, primary, Icon}) => (
                <button
                  className={classNames(
                    'flex gap-x-2 rounded-full border-2 bg-none px-4 py-2 text-sm font-medium text-white ring-offset-gray-700/80 hover:bg-gray-700/80 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-base',
                    primary ? 'border-orange-500 ring-orange-500' : 'border-white ring-white',
                  )}
                  
                  key={text}>
                    <Link to={href} smooth={true}>
                  {text}
                  {Icon && <Icon className="h-5 w-5 text-white sm:h-6 sm:w-6" />}
                  </Link>
                </button>
              ))}
            </div>
            </div>
            </div>
            <div className="absolute inset-x-0 bottom-6 flex justify-center">
          <button className="rounded-full bg-white p-1 ring-white ring-offset-2 ring-offset-gray-700/80 focus:outline-none focus:ring-2 sm:p-2" >
          <Link to='about' smooth={true}>
            
            <ChevronDownIcon className="h-5 w-5 bg-transparent sm:h-6 sm:w-6" />
          </Link>
          </button>
        </div>
        </div>
</div>
)
}