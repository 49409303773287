import GithubIcon from './Icon/GithubIcon'
import LinkedInIcon from './Icon/LinkedInIcon'
export default function Socals(){

        return (
                
                <div className="flex">
                <a
                    aria-label='Github'
                    className="-m-1.5 rounded-md p-1.5 transition-all duration-300 hover:text-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-500  sm:-m-3 sm:p-3"
                    href="https://github.com/Omegashadow112"
                    key='Github'>
                    <GithubIcon className="h-5 w-5 align-baseline sm:h-6 sm:w-6" />
                </a>
                <div className="w-2"></div>
                <a
                    aria-label='LinkedIn'
                    className="-m-1.5 rounded-md p-1.5 transition-all duration-300 hover:text-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-500  sm:-m-3 sm:p-3"
                    href="https://www.linkedin.com/in/daniel-heinrich-b7510b248/"
                    key='LinkedIn'>
                    <LinkedInIcon className="h-5 w-5 align-baseline sm:h-6 sm:w-6" />
                </a>
                </div>
        )
}