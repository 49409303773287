import './App.css';
import Hero from './components/hero';
import About from './components/about';
import Resume from './components/resume';
import Contact from './components/contact';
import Footer from './components/footer';
import './App.css';


function App() {
  return (
    <div>
      <Hero> </Hero>
      <About> </About>
      <Resume> </Resume>
      <Contact> </Contact>
      <Footer> </Footer>
    </div>
  );
}

export default App;
