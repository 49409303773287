import classNames from 'classnames';
import profile from '../images/profilepic.jpg';
import {
    AcademicCapIcon,
    CalendarIcon,
    MapIcon,
    SparklesIcon,
  } from '@heroicons/react/24/outline';
export default function About(){
    let aboutItems = [
        {label: 'Location', text: 'Brisbane, Queensland', Icon: MapIcon},
        {label: 'Age', text: '21', Icon: CalendarIcon},
        {label: 'Interests', text: 'Programming, Video Games ,Tabletop Roleplaying Games ', Icon: SparklesIcon},
        {label: 'Study', text: 'Queensland University of Technology', Icon: AcademicCapIcon},
      ]

return (
    <div className='bg-neutral-800 ' id="about">
<div className={classNames("px-4 py-16 md:py-24 lg:px-8",'mx-auto max-w-screen-lg')}>
<div className={classNames('grid grid-cols-1 gap-y-4', 'md:grid-cols-4')}>
<div className="col-span-1 flex justify-center md:justify-start">
            <div className="relative h-24 w-24 overflow-hidden rounded-xl md:h-32 md:w-32">
                <img className="h-full w-full object-cover" src={profile} alt="Profile" />
    </div>
    </div>
    <div className={classNames('col-span-1 flex flex-col gap-y-6', 'md:col-span-3')}>
          <div className="flex flex-col gap-y-2">
            <h2 className="text-2xl font-bold text-white" >About me</h2>
            <p className="prose prose-sm text-gray-300 sm:prose-base">
            I'm a skilled software engineer based in Brisbane who specializes in high-performance system programming and full stack website development. Building strong, effective solutions that address organizations' changing demands is what I'm most passionate about. I excel in dynamic workplaces that call for creative thinking and rapid problem-solving since I have a strong understanding of the most recent technologies and programming languages.
            </p>
            <p className="prose prose-sm text-gray-300 sm:prose-base">
            I focus my creative energy outside of work on personal projects where I experiment with cutting-edge development techniques and new technologies. This helps me become more adept at overcoming difficult problems while also keeping me current with industry developments. In the immersive world of video games, where I admire the fusion of artistic talent and technological power. In addition, I adore the companionship and imaginative storytelling that tabletop roleplaying games offer, frequently participating in exciting adventures with a close-knit group of friends.
            </p>
            <p className="prose prose-sm text-gray-300 sm:prose-base">
            With a strong enthusiasm for software engineering and a dedication to lifelong learning, I'm thrilled to lend my knowledge to cutting-edge initiatives that push the limits of technology.
              </p>
          </div>
          <ul className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            {aboutItems.map(({label, text, Icon}, idx) => (
              <li className="col-span-1 flex  items-start gap-x-2" key={idx}>
                {Icon && <Icon className="h-5 w-5 text-white" />}
                <span className="text-sm font-bold text-white">{label}:</span>
                <span className=" text-sm text-gray-300">{text}</span>
              </li>
            ))}
          </ul>
    </div>
    </div>
</div>
</div>
)
}
