import classNames from "classnames"
import {EnvelopeIcon} from '@heroicons/react/24/outline';
import 'react-toastify/dist/ReactToastify.css';
import GithubIcon from './Icon/GithubIcon'
import LinkedInIcon from "./Icon/LinkedInIcon";
import React, { useCallback} from 'react';
import { toast,ToastContainer } from 'react-toastify';

export default function Contact() {
    const handleSendMessage = useCallback(
        async (event) => {
          event.preventDefault();
          /**
           * This is a good starting point to wire up your form submission logic
           * */
    
          const formData = new FormData(event.currentTarget);
    
          formData.append("access_key", "dedfd296-81b6-41e1-b1f5-954a335b4517");
      
          const res = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            body: formData
          }).then((res) => res.json());
      
          if (res.success) {
            toast.success('Message sent successfully');
          } else {
            toast.error('Message not sent');
          }
        },
        [],
      );
      const inputClasses =
    'bg-neutral-700 border-0 focus:border-0 focus:outline-none focus:ring-1 focus:ring-orange-600 rounded-md placeholder:text-neutral-400 placeholder:text-sm text-neutral-200 text-sm';

    return (
        <div className="bg-neutral-800" id="contact">
        <div className={classNames("px-4 py-16 md:py-24 lg:px-8",'mx-auto max-w-screen-lg')}>
        <div className="flex flex-col gap-y-6">
        <div className="flex flex-col gap-6 md:flex-row md:items-center">
        <EnvelopeIcon className="hidden h-16 w-16 text-white md:block"/>
        <h2 className="text-2xl font-bold text-white" >Get in touch.</h2>
        </div>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <div className="order-2 col-span-1 md:order-1 ">
            {/*add the contact form here*/}
      <form className="grid min-h-[320px] grid-cols-1 gap-y-4" method="POST" onSubmit={handleSendMessage}>
      <input className={inputClasses} name="name"  placeholder=" Name" required type="text" />
      <input
        autoComplete="email"
        className={inputClasses}
        name="email"
    
        placeholder=" Email"
        required
        type="email"
      />
      <textarea
        className={inputClasses}
        maxLength={250}
        name="message"
    
        placeholder=" Message"
        required
        rows={6}
      />
      <button
        aria-label="Submit contact form"
        className="w-max rounded-full border-2 border-orange-600 bg-stone-900 px-4 py-2 text-sm font-medium text-white shadow-md outline-none hover:bg-stone-800 focus:ring-2 focus:ring-orange-600 focus:ring-offset-2 focus:ring-offset-stone-800"
        type="submit">
        Send Message
      </button>
      <ToastContainer />
    </form>
            </div>
            <div className="order-1 col-span-1 flex flex-col gap-y-4 md:order-2">
            <p className="prose leading-6 text-neutral-300">The best way to contact me is by email or by using the form.</p>
            <dl className="flex flex-col space-y-4 text-base text-neutral-500 sm:space-y-2">
              {/*Email*/}
                  <div key='Email'>
                    <dt className="sr-only">Email</dt>
                    <dd className="flex items-center">
                      <a
                        className={classNames(
                          '-m-2 flex rounded-md p-2 text-neutral-300 hover:text-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-500',
                          {'hover:text-white': 'mailto:danielheinrichemail@gmail.com'},
                        )}
                        href='mailto:danielheinrichemail@gmail.com'
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <EnvelopeIcon aria-hidden="true" className="h-4 w-4 flex-shrink-0 text-neutral-100 sm:h-5 sm:w-5" />
                        <span className="ml-3 text-sm sm:text-base">danielheinrichemail@gmail.com</span>
                      </a>
                    </dd>
                  </div>
                
                {/*Github*/}
                <div key='Github'>
                    <dt className="sr-only">Github</dt>
                    <dd className="flex items-center">
                      <a
                        className={classNames(
                          '-m-2 flex rounded-md p-2 text-neutral-300 hover:text-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-500',
                          {'hover:text-white': 'https://github.com/Omegashadow112'},
                        )}
                        href='https://github.com/Omegashadow112'
                        target="_blank"
                        rel="noopener noreferrer">
                        <GithubIcon aria-hidden="true" className="h-4 w-4 flex-shrink-0 text-neutral-100 sm:h-5 sm:w-5" />
                        <span className="ml-3 text-sm sm:text-base">Omegashadow112</span>
                      </a>
                    </dd>
                  </div>

                    {/*linkedin*/}
                <div key='Linkedin'>
                    <dt className="sr-only">Linkedin</dt>
                    <dd className="flex items-center">
                      <a
                        className={classNames(
                          '-m-2 flex rounded-md p-2 text-neutral-300 hover:text-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-500',
                          {'hover:text-white': 'https://www.linkedin.com/in/daniel-heinrich-b7510b248/'},
                        )}
                        href='https://www.linkedin.com/in/daniel-heinrich-b7510b248/'
                        target="_blank"
                        rel="noopener noreferrer">
                        <LinkedInIcon aria-hidden="true" className="h-4 w-4 flex-shrink-0 text-neutral-100 sm:h-5 sm:w-5" />
                        <span className="ml-3 text-sm sm:text-base">Daniel Heinrich</span>
                      </a>
                    </dd>
                  </div>
            </dl>
          </div>
            </div>
          </div>
        </div>
        </div>

    )
}